import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { emptyFormData } from "../../Util/FormUtil";
import { Box, Skeleton, TextField } from "@mui/material";

export default function TextInput(props: any) {

    const { register, setValue, setFocus } = useForm();
    const reg: any = register(props.name);
    const [data, setData] = props.state ? props.state : [null, () => {}];
    const [error, setError] = useState<string | null>(null);
    const changeTmr: any = useRef(null);
    const def: any = { fullWidth: true, margin: "normal", size: "small", variant: "outlined" };
    const cancel = data && data.cancel;
    const defaultValue = (props.defaultValue !== undefined) ? props.defaultValue : (props.data && props.data[props.name] !== undefined ? props.data[props.name] : "");
    const value = (data && data.data[props.name] !== undefined) ? data.data[props.name] : defaultValue;
    const validate = props.validate?.toLowerCase();

    let merged: any = {...def, 
        ...props, 
        ...{ onBlur: reg.onBlur, ref: reg.ref },
        defaultValue: defaultValue,
        disabled: data && data.saving ? true : props.disabled,
        error: !!error,
        helperText: error || ((props.multiline && props.maxLength && value) ? `${value.length} of ${props.maxLength} max characters` : null),
        onChange: (e: any) => { 
            clearTimeout(changeTmr.current);
            changeTmr.current = setTimeout(() => { 
                let val = e.target.value;
                if (props.maxLength && val.length > props.maxLength) {
                    val = val.substring(0, props.maxLength);
                    setValue(props.name, val);
                }
                const mod = (val !== defaultValue)
                setData((prev: any) => {
                    if (mod) { 
                        prev.data[e.target.name] = val;
                        prev.modified = true; 
                    } 
                    else {
                        delete prev.data[e.target.name];
                        prev.modified = Object.keys(prev.data).length > 0;
                    }
                    return {...prev};
                });
                reg.onChange(e);
                if (props.onChange) {
                    props.onChange(e, (mod) ? val : null); 
                }
            }, 50);
        },
        onBlur: (e: any) => {
            if (error) {
                setFocus(props.name);
            }
        },
        className: (props.className || "") + ((data && data.data[props.name] !== undefined) ? " modified" : "").trim()
    };
    delete merged.state;
    delete merged.isLoading;
    delete merged.data;

    if (value) {
        if (!merged['InputLabelProps']) { merged['InputLabelProps'] = {}; }
        merged['InputLabelProps'].shrink = true;
    }

    useEffect(() => {
        if (validate === 'email') {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (value && !re.test(String(value).toLowerCase())) {
                setError('Invalid email address');
            } 
            else {
                setError(null);
            }
        }
        else if (validate === 'url') {
            const re = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$", // fragment locator
                "i"
            );
            if (value && !re.test(String(value).toLowerCase())) {
                setError('Invalid URL address');
            } 
            else {
                setError(null);
            }
        }
    }, [validate, value]);

    useEffect(() => {
        if (cancel) { 
            setValue(props.name, defaultValue);
            setData(emptyFormData());
        }
    }, [cancel, props.name, defaultValue, setData, setValue]);

    return (props.isLoading || false) ? (
        <Box sx={{ pt: 2, pb: 1 }} >
            <Skeleton variant="rounded" height={"2.5rem"} />
        </Box>
    ) : (
        <TextField {...merged} />
    );
}
