import { Box, IconButton, Paper, Typography } from "@mui/material";
import { T } from "../../../../Util/Localize";
import ActionButtons from "../../../../Components/Input/ActionButtons";
import { useEffect, useState } from "react";
import { FormData, emptyFormData, errorData } from "../../../../Util/FormUtil";
import TextInput from "../../../../Components/Input/TextInput";
import Api from "../../../../Util/Api";
import { useLoad } from "../../../../Util/Hooks";
import SwitchInput from "../../../../Components/Input/SwitchInput";
import { enqueueSnackbar } from "notistack";
import { MultiplicationSignIcon } from "@hugeicons/react";

interface PolicyDetailsProps {
    policyId: number | null;
    isMobile: boolean;
    onClose: any;
    policyUpdate: any;
}

export default function PolicyDetails({ policyId, isMobile, onClose, policyUpdate }: PolicyDetailsProps) {

    const [data, setData] = useState<any>(null);
    const [policy, setPolicy] = useState<FormData>(emptyFormData());
    const isLoading = (data === null);

    useLoad(() => {
        if (policyId) {
            Api.get('policy/' + policyId).then((r: any) => {
                if (r.ok) { 
                    setData(r.data);
                }
                else {
                    enqueueSnackbar('Unable to load policy', { variant: 'error' });
                }
            });
        }
        else {
            setData({ title: '', content: '', note: '', status: 0 });
        }
    });

    const saveData = async (updates: any, setUpdates: any) => {
        let update;
        if (policyId) {
            update = await Api.patch('policy/' + policyId, updates);
        }
        else {
            update = await Api.put('policy', {...{ title: '', content: '', note: '', status: 0 }, ...updates});
            if (update.ok) {
                updates.pid = update.data.pid;
                updates.updated = (new Date());
            }
        }
        if (update.ok) { 
            setData((prev: any) => { 
                const newData = { ...prev, ...updates };
                policyUpdate(newData);
                return (newData);
            }); 
            if (onClose) { onClose(); }
        }
        else {
            errorData(update, setUpdates);
            enqueueSnackbar('Unable to update policy', { variant: 'error' });
        }
    }

    useEffect(() => {
        if (policy.cancel) {
            if (onClose) { onClose(); }
        }
    }, [policy.cancel, onClose]);

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column">
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T((policyId ? "Update" : "Create") + (isMobile ? " Agreement" : " Team Member Agreement"))}
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>
                <Paper className="content" sx={{ flex: 1 }}>
                    <Box flex={1} display="flex" flexDirection="column" sx={{ overflow: 'auto' }} height="100%">
                        <TextInput
                            name="title"
                            label={T("Agreement Name")}
                            data={data}
                            state={[policy, setPolicy]}
                            isLoading={isLoading}
                        />
                        <SwitchInput
                            name="status"
                            label={T("Active")}
                            data={data}
                            state={[policy, setPolicy]}
                            isLoading={isLoading}
                            true={1}
                            false={0}
                        />
                        <Box flex={1} className="fill-height">
                            <TextInput
                                name="content"
                                label={T("Agreement/Policy Content")}
                                data={data}
                                state={[policy, setPolicy]}
                                isLoading={isLoading}
                                multiline
                                minRows={2}
                                maxLength={100000}
                                inputProps={{ style: { minHeight: '5rem' } }}
                            />
                        </Box>
                        <TextInput
                            name="note"
                            label={T("Internal Notes (optional)")}
                            data={data}
                            state={[policy, setPolicy]}
                            isLoading={isLoading}
                            multiline
                            maxLength={1000}
                            minRows={2}
                            maxRows={5}
                        />
                    </Box>
                </Paper>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, mt: 0, pb: 2 }}>
                <ActionButtons active
                    onSave={saveData} 
                    state={[policy, setPolicy]}
                />
            </Box>
        </Box>
    );
}
