import { enqueueSnackbar } from 'notistack';
import Api from './Api';

export function clipboardSetText(text: string) { 
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`"${text}" copied to clipboard`, { variant: 'info' });
}

export function getBanners(): any { 
    return {
        "1": "Just Listed",
        "2": "Just Reduced",
        "3": "Pending",
        "4": "Sold",
        "5": "Vacation Rental",
        "6": "Pocket Listing",
        "7": "Coming Soon",
        "8": "Back on the Market",
        "9": "Rented",
        "10": "Rental",
        "11": "Multi-Family",
        "12": "For Lease",
        "13": "Auction",
        "14": "In Contract",
        "15": "AirBnB",
        "16": "Luxury Listing",
        "17": "Business Opportunity",
        "18": "Land for Sale",
        "19": "Similar Home Shown",
        "20": "For Sale",
        "21": "Move-in Ready",
        "22": "Waterfront",
        "23": "Water Views",
        "24": "New to Market",
        "25": "Pre-Construction",
        "26": "New Construction",
        "27": "Beach House",
        "28": "Pool",        
    }; 
}

export function getStates(): any {
    return {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey", 
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    }
}

export function hasRole(role: string): boolean {
    return Api.user.roles.indexOf(role) !== -1;
}

export function mediaSize(sizes: string, width: number): string | null {
    if (!sizes) { return null; }
    return (sizes.split(',').find((size) => (parseInt(size.split(':')[1]) >= width )) || sizes.split(',')[0]).split(':')[0];
}

export function setBodyClass(className: string, include: boolean) { 
    if (document.body.className.split(' ').indexOf(className) === -1) {
        if (include) {
            document.body.classList.add(className);
        }
    }
    else {
        if (!include) {
            document.body.classList.remove(className);
        }
    }
}

export function titleCase(str: string): string {
    return str.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase());
}

