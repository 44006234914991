import { Box, IconButton, Paper, Typography } from '@mui/material';
import { ReactElement, ReactNode, useRef, useState } from 'react';
import { ArrowUp01Icon } from '@hugeicons/react';

interface PaperCollapseProps {
    children?: ReactNode,
    title: string,
    icon?: ReactElement,
    open?: boolean,
    className?: string
}
export default function PaperCollapse(props: PaperCollapseProps) {

    const [open, setOpenEx] = useState(props.open);
    const [childrenHeight, setChildrenHeight] = useState<any>(open ? 'auto' : 0);
    const containerRef = useRef<HTMLDivElement>(null);
    const childrenRef = useRef<HTMLDivElement>(null);
    const tmr = useRef<any>(null);
    
    const setOpen = (open: boolean, childrenRef: any) => {
        if (open) {
            setOpenEx(true);
            setChildrenHeight(childrenRef.current ? childrenRef.current.offsetHeight : 'auto');
            if (tmr.current) { window.clearTimeout(tmr.current); }
            tmr.current = window.setTimeout(() => { setChildrenHeight('auto'); }, 500);
        }
        else {
            setOpenEx(false);
            if (childrenRef.current) { setChildrenHeight(childrenRef.current.offsetHeight); }
            if (tmr.current) { window.clearTimeout(tmr.current); }
            tmr.current = window.setTimeout(() => { setChildrenHeight(0); }, 10);
        }
    }

    return (
        <Paper className={(props.className || "") + " content stripe" + (open ? " opened" : " collapsed")}>
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', userSelect: 'none', padding: 2, margin: -2 }} onClick={() => setOpen(!open, childrenRef)}>
                {props.icon && (
                    <Box display="flex" alignItems="center" width={36} sx={{ color: 'var(--primary-dark, #1565C0)' }}>
                        {props.icon}
                    </Box>
                )}
                <Box flex={1}>
                    <Typography variant="h6">
                        {props.title}
                    </Typography>
                </Box>
                <IconButton className={open ? "flipped" : ""} onClick={() => setOpen(!open, childrenRef)}>
                    <ArrowUp01Icon />
                </IconButton>
            </Box>
            <Box ref={containerRef} sx={{ overflow: 'hidden', height: childrenHeight, transition: 'height 0.3s ease-in-out' }}>
                <Box ref={childrenRef}>
                    {props.children}
                </Box>
            </Box>
        </Paper>
    );
}
