import { Avatar, Box, Divider, MenuItem, ListItemIcon, ListItemText, MenuList, Paper, Switch, Typography, Button } from "@mui/material";
import { ArrowDown01Icon, Door01Icon, UserAccountIcon, MoneyBag02Icon, DocumentValidationIcon, AnalysisTextLinkIcon, Key02Icon, AppleNewsIcon, LocationUser04Icon, Logout03Icon } from "@hugeicons/react";
import { Link, useLocation } from "react-router-dom";
import { mediaSize } from "../../Util/Functions";
import { T } from "../../Util/Localize";
import Api from "../../Util/Api";
import { useEffect, useState } from "react";


export default function ProfileMenu(props: any) {

    const [menuOpen, setMenuOpen] = useState(false);

    const location = useLocation();

    useEffect(() => { setMenuOpen(false); }, [location]);

    return (
        <Box>
            <Paper
                elevation={(menuOpen) ? 5 : 0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 100,
                    p: 0.5,
                    pr: (props.isMobile  ? 0.5 : 1.25),
                    cursor: 'pointer',
                    userSelect: 'none',
                    position: 'relative',
                    zIndex: 99
                }}
                onClick={() => { setMenuOpen(!menuOpen); }}
            >
                <Avatar
                    src={"https://media.hd.pics/" + mediaSize(Api.user.userMedia, 100) + "." + Api.user.userMediaExt + "?w=60"}
                    alt={Api.user.userName}
                    sx={{ width: (props.isMobile ? 32: 24), height: (props.isMobile ? 32: 24) }}
                />
                {(!props.isMobile) && (
                    <>
                        <Typography variant="body2" sx={{ mx: 0.75 }}>{Api.user.userName}</Typography>
                        <ArrowDown01Icon size={12} />
                    </>
                )}
            </Paper>
            {(menuOpen) ? (
                <>
                    <Box sx={{ background: 'rgba(0,0,0,0.5);', backdropFilter: 'blur(0.25em)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 98 }} onClick={() => { setMenuOpen(false); }}></Box>
                    <Box sx={{ position: 'relative' }}>
                        <Paper sx={{ position: 'absolute', right: 0, top: 0, width: 240, mt: 1, zIndex: 99, px: 2, py: 1 }} elevation={7}>
                            <MenuList>
                                <MenuItem component={Button} onClick={() => { document.location = '/Dashboard'; }} sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <Door01Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Back to Legacy Site")} sx={{ textTransform: 'none' }} />
                                </MenuItem>
                                <Divider />
                                <MenuItem component={Link} to="/ux/Account/Basic" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <UserAccountIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Profile")} />
                                </MenuItem>
                                <MenuItem component={Link} to="/ux/Account/Earnings" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <MoneyBag02Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Earnings")} />
                                </MenuItem>
                                <MenuItem component={Link} to="/ux/Account/ListingPages" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <DocumentValidationIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Listing Pages")} />
                                </MenuItem>
                                <MenuItem component={Link} to="/ux/Account/Reports" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <AnalysisTextLinkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Reports")} />
                                </MenuItem>
                                <MenuItem component={Link} to="/ux/Account/Password" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <Key02Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Password")} />
                                </MenuItem>
                                <MenuItem component={Link} to="/ux/Account/AppIntegrations" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <AppleNewsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Apps")} />
                                </MenuItem>
                                <MenuItem component={Link} to="/ux/Account/ServiceAreas" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <LocationUser04Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Service Areas")} />
                                </MenuItem>
                                <Divider />
                                <MenuItem sx={{ pl: 0 }} >
                                    <Switch defaultChecked color="primary" />
                                    <Typography variant="body2">{T("Always signed-in")}</Typography>
                                </MenuItem>
                                <Divider />
                                <MenuItem component={Link} to="/ux/Dashboard" sx={{ py: 1 }}>
                                    <ListItemIcon>
                                        <Logout03Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={T("Sign out")} />
                                </MenuItem>
                            </MenuList>
                        </Paper>
                    </Box>
                </>
            ) : null}
        </Box>
    )
}
