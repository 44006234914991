import { cancelData, saveData } from "../../Util/FormUtil";
import { Box, Button, CircularProgress } from "@mui/material";

export default function ActionButtons(props: any) {

    const [data, setData] = props.state ? props.state : [null, () => {}];
    const isLoading = props.isLoading || false;
    const modified = data ? data.modified : false;
    const saving = data ? data.saving : false;

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center" className={"animbuttons" + ((modified || saving || props.active) ? " active" : "")}>
            <Button 
                variant="outlined" 
                sx={{ mr: 2, mt: 2 }} 
                disabled={isLoading} 
                onClick={() => { 
                    if (props.onCancel) { props.onCancel(); }
                    if (props.state) { cancelData(setData); }
                }}
            >
                Cancel
            </Button>
            <Button 
                variant="contained" 
                color="primary" sx={{ mt: 2 }} 
                disabled={isLoading || !modified} 
                onClick={() => { saveData(setData, props.onSave); }}
            >
                {(!saving) ? "Save" : <CircularProgress size={24} sx={{ color: 'inherit' }} />}
            </Button> 
        </Box>
    );
}
