import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { T } from "../../../../Util/Localize";
import ActionButtons from "../../../../Components/Input/ActionButtons";
import { useState } from "react";
import { useLoad } from "../../../../Util/Hooks";
import { MultiplicationSignIcon } from "@hugeicons/react";

interface MarketingKitPageDesignProps {
    onClose: any
}

export default function MarketingKitPageDesign({ onClose }: MarketingKitPageDesignProps) {

    const [data, setData] = useState<any>(null);
    const isLoading = (data === null);

    useLoad(() => {
        console.log('load');
        setData({});
    });

    const saveData = async (updates: any, setUpdates: any) => {
        console.log('save');
    }

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column">
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T("Marketing Kit Page Design")}
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>
                <Box flex="1" sx={{ overflow: 'auto' }}>
                    <Paper className="content">
                        <Typography variant="body1" color="text.primary" gutterBottom>
                            {T("Change the order of the sections by dragging/dropping the order of the sections below.")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {T("Note: If you have Marketing Kit features disabled (Default Settings > Disabled Features) then those sections will already be hidden by default.")}
                        </Typography>
                        <Box sx={{ mt: 2, mb: 1 }}>
                            <Button variant="outlined">Social Media Reels</Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, pb: 2 }}>
                <ActionButtons active
                    isLoading={!isLoading}
                    onSave={saveData} 
                    onCancel={() => { if (onClose) { onClose(); } }}
                />
            </Box>
        </Box>
    );
}
