import { jwtDecode } from 'jwt-decode';

interface ApiInterface {
    apiLog: any;
    apiNotify?: any;
    accessToken?: string;
    cookieJar?: any;
    databaseLog?: any;
    databaseNotify?: any;
    eventLog?: any;
    headers: any;
    refreshToken?: string;
    refreshTimer?: any;
    requestLog?: any;
    sessionKey?: string;
    user?: any
    validTo?: Date;
    apiUrl(path: string): string;
    authorize(sessionKey?: string): Promise<string | Boolean | undefined>;
    cookies(): any;
    get(url: string, data?: any, skipauth?: Boolean): any;
    hasTouchScreen(): boolean;
    isMobile(): boolean;
    login(email: string, password: string): Promise<string | Boolean | undefined>;
    logApiRequest(method: string, url: string, data: any): void;
    patch(url: string, data?: any, skipauth?: Boolean): any;
    post(url: string, data?: any, skipauth?: Boolean): any;
    prepData(data: any): any;
    put(url: string, data?: any, skipauth?: Boolean): any;
    refresh(): Promise<string | Boolean | undefined>;
    response(response: any, event: any): any;
    sessionFromCookie(): string | null;
    sessionUpdate(data: any): void;
}

const Api: ApiInterface = {

    apiLog: [],

    databaseLog: [],

    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "hd-version": "2.0"
    },

    user: {},

    apiUrl: function(path: string) { return `/api/v2/${path}`; },

    authorize: async function(sessionKey?: string | null) {
        if (Api.accessToken) { return Api.accessToken; }
        if (!sessionKey) {
            sessionKey = Api.sessionFromCookie();
            if (!sessionKey) {
                return false;
            }
        }
        var response = await fetch(String(Api.apiUrl("Auth/authorize")), {
            method: "POST", 
            headers: Api.headers,
            body: JSON.stringify({ "sessionKey": sessionKey })
        });
        if (response.ok) {
            var data: any = await response.json();
            Api.sessionUpdate(data);
            return Api.accessToken;
        }
        else {
            return false;
        }
    },

    cookies: function() {
        if (Api.cookieJar) { return Api.cookieJar; }
        var cookies = document.cookie.split(';');
        var jar: any = Api.cookieJar = {};
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = (eqPos > -1) ? cookie.substring(0, eqPos) : cookie;
            jar[name.trim()] = cookie.substring(eqPos + 1);
        }
        return jar;
    },

    get: async function(url: string, data?: any, skipauth?: Boolean): Promise<any> {
        const event: any = Api.logApiRequest("GET", url, data);
        var headers: any = {...Api.headers};
        if (!skipauth) {
            if (!Api.accessToken) {
                var auth = await Api.authorize();
                if (!auth) {
                    console.error("could not authorize api");
                    return false;
                }
            }
            headers.Authorization = `Bearer ${Api.accessToken}`;
        }
        var url2: string = Api.apiUrl(url);
        if (data) {
            var query = Object.keys(data)
                .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
                .join('&');
            if (query.length) {
                url2 += ((url2.indexOf('?') < 0) ? '?' : '&') + query;
            }
        }
        var response: any = await fetch(String(url2), { headers });

        if (response.status === 401) {
            // var r2 = await Api.refresh();
            // if (r2) {
            //     return await Api.post(url, data);
            // }
        }
        return Api.response(response, event);
    },

    hasTouchScreen: () => {
        if ("maxTouchPoints" in navigator) {
            return navigator.maxTouchPoints > 0;
        } 
        else {
            const mQ = window.matchMedia && window.matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                return !!mQ.matches;
            } 
            else if ("orientation" in window) {
                return true;
            }
        }
        return false;
    },

    isMobile: () => {
        return (Api.hasTouchScreen() || (window.innerWidth <= 768));
    },

    login: async function(email: string, password: string | null) {
        if (Api.accessToken) { return Api.accessToken; }
        var response = await fetch(String(Api.apiUrl("Auth/login")), {
            method: "POST", 
            headers: Api.headers,
            body: JSON.stringify({ "userName": email, "password": password, "brandId": 0, "sScreenRes": String(window.screen.width + "x" + window.screen.height) })
        });
        if (response.ok) {
            var data: any = await response.json();
            Api.sessionUpdate(data);
            return Api.accessToken;
        }
        else {
            return false;
        }
    },

    logApiRequest: function(method: string, url: string, data: any) {
        var log = Api.apiLog;
        var event = { page: window.location.pathname, method, url, data, start: new Date() };
        log.unshift(event);
        if (log.length > 100) { log.pop(); }
        if (Api.apiNotify) { Api.apiNotify(log); }
        return event;
    },

    patch: async function(url: string, data?: any, skipauth?: Boolean): Promise<any> {
        const event: any = Api.logApiRequest("PATCH", url, data);
        var headers: any = {...Api.headers};
        if (!skipauth) {
            if (!Api.sessionKey) {
                var auth = await Api.authorize();
                if (!auth) {
                    console.error("could not authorize api");
                    return false;
                }
            }
            headers.Authorization = `Bearer ${Api.accessToken}`;
        }
        var response: any = await fetch(String(Api.apiUrl(url)), {
            method: "PATCH",
            headers,
            body: Api.prepData(data)
        });

        if (response.status === 401) {
            // var r2 = await Api.refresh();
            // if (r2) {
            //     return await Api.post(url, data);
            // }
        }
        return Api.response(response, event);
    },

    post: async function(url: string, data?: any, skipauth?: Boolean): Promise<any> {
        const event: any = Api.logApiRequest("POST", url, data);
        if (skipauth === undefined) { skipauth = false; }
        if (!skipauth) {
            if (!Api.sessionKey) {
                var auth = await Api.authorize();
                if (!auth) {
                    console.error("could not authorize api");
                    return false;
                }
            }
        }

        var headers: any = {...Api.headers};

        if (!skipauth) {
            if (Api.sessionKey) {
                headers.sid = Api.sessionKey;
            } else {
                headers.Authorization = `Bearer ${Api.accessToken}`;
            }
        }
        var response: any = await fetch(String(Api.apiUrl(url)), {
            method: "POST",
            headers,
            body: data
        });

        if (response.ok) {
            return response;
        } 
        else {
            if (response.status === 401) {
                var r2 = await Api.refresh();
                if (r2) {
                    return await Api.post(url, data);
                }
            }
        }
        return Api.response(response, event);
    },

    prepData: function(data: any) {
        if (data && typeof data === "object") {
            return JSON.stringify(data);
        }
        return data;
    },

    put: async function(url: string, data?: any, skipauth?: Boolean): Promise<any> {
        const event: any = Api.logApiRequest("PUT", url, data);
        var headers: any = {...Api.headers};
        if (!skipauth) {
            if (!Api.sessionKey) {
                var auth = await Api.authorize();
                if (!auth) {
                    console.error("could not authorize api");
                    return false;
                }
            }
            headers.Authorization = `Bearer ${Api.accessToken}`;
        }
        var response: any = await fetch(String(Api.apiUrl(url)), {
            method: "PUT",
            headers,
            body: Api.prepData(data)
        });

        if (response.status === 401) {
            // var r2 = await Api.refresh();
            // if (r2) {
            //     return await Api.post(url, data);
            // }
        }
        return Api.response(response, event);
    },

    refresh: async function() {
        var response = await fetch(String(Api.apiUrl('auth/refresh-token')), {
            method: "POST",
            headers: Api.headers,
            body: JSON.stringify({
                accessToken: Api.accessToken,
                refreshToken: Api.refreshToken
            })
        });
        if (response.ok) {
            var data: any = await response.json();
            Api.sessionUpdate(data);
            return true;
        }
        return false;
    },

    response: async function(response: any, event: any) {
        let json: any = {};
        try {
             json = await response.json();
        } catch (e) {
            json = { error: e };
        }
        if (event) {
            event.end = new Date();
            event.duration = event.end.getTime() - event.start.getTime();
            event.status = response.status;
            event.response = response;
            if (Api.apiNotify) { Api.apiNotify(Api.apiLog); }
            if (json && json.database) {
                const path = new URL(response.url).pathname;
                var log = [...json.database.map((d: any) => ({ page: window.location.pathname, url: path, ...d })), ...Api.databaseLog];
                if (log.length > 100) { log.length = 100; }
                if (Api.databaseNotify) { Api.databaseNotify(log); }
                Api.databaseLog = log;
            }
        }
        return {
            ...json,
            headers: response.headers,
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            statusText: response.statusText,
            url: response.url
        }        
    },

    sessionFromCookie: function() {
        return Api.cookies()['sid' + String(document.location.hostname.split('.').length - 1)];
    },

    sessionUpdate: function(data: any) {
        const decodedToken = jwtDecode<any>(data.accessToken);
        Api.accessToken = data.accessToken;
        Api.refreshToken = data.refreshToken;
        Api.validTo = new Date(data.validTo);
        Api.user = {
            brandId: data.brand,
            userId: data.userId,
            userType: data.userType,
            userName: data.userName,
            firstName: data.firstName,
            lastName: data.lastName,
            userMedia: data.userMedia,
            userMediaExt: data.userMediaExt,
            superUserId: data.superUserId,
            userUserType: data.userUserType,
            hasPaymentProcessor: true, // TODO
            isAdmin: (data.userType === 1) || (data.superUserType === 1),
            isParentBrand: false, // TODO
            roles: [...Object.keys(decodedToken).filter(k => k.endsWith('/role')).map(k => decodedToken[k])]
        }
        var diff = (Api.validTo.getTime() - Date.now()) - 10000;
        if (Api.refreshTimer) { clearTimeout(Api.refreshTimer); }
        Api.refreshTimer = setTimeout(Api.refresh, diff);
    }
}

export default Api;
