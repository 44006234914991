import { useEffect, useState } from "react";
import { emptyFormData } from "../../Util/FormUtil";
import { Box, FormControlLabel, Skeleton, Switch } from "@mui/material";

export default function SwitchInput(props: any) {

    const defaultChecked: any = (props.defaultChecked !== undefined) ? props.defaultChecked : (props.data && props.data[props.name] !== undefined ? props.data[props.name] : "");
    const [checked, setChecked] = useState(defaultChecked || false); 
    const [data, setData] = props.state ? props.state : [null, () => {}];
    const cancel = data && data.cancel;

    let merged: any = {
        ...props, 
        disabled: data && data.saving ? true : props.disabled,
        onChange: (e: any) => { 
            const val = (e.target.checked && props.true) ? props.true : (!e.target.checked && (props.false !== undefined)) ? props.false : e.target.checked;
            const mod = val !== defaultChecked
            setData((prev: any) => {
                if (mod) { 
                    prev.data[e.target.name] = val
                    prev.modified = true; 
                }
                else {
                    delete prev.data[e.target.name];
                    prev.modified = Object.keys(prev.data).length > 0;
                }
                return {...prev};
            });
            setChecked(val);
            if (props.onChange) {
                props.onChange(e, (mod) ? val : null); 
            }
        },
        className: (props.className || "") + ((data && data.data[props.name] !== undefined) ? " modified" : "").trim()
    };
    delete merged.state;
    delete merged.isLoading;
    delete merged.data;

    useEffect(() => { setChecked(defaultChecked); }, [defaultChecked]);

    useEffect(() => {
        if (cancel) { 
            setData(emptyFormData());
            setChecked(defaultChecked);
        }
    }, [cancel, defaultChecked, setData]);

    return (props.isLoading || false) ? (
        <Box display="flex" alignItems="center" sx={{ pt: 2, pb: 1 }} >
            <Skeleton variant="circular" height="2.5rem" width="2.5rem" sx={{ mr: 2 }} />
            <Box sx={{ flexGrow: 1 }}>
                <Skeleton variant="rounded" height="2rem" width="100%" />
            </Box>
        </Box>
    ) : (
        <FormControlLabel {...merged}
            control={<Switch checked={(props.true !== undefined) ? (checked === props.true) : Boolean(checked)} />}
            label={merged.label}
        />
    );
}
