
let translations: { [key: string]: string } = {};

export type DateFormatType = 'DATE_TIME' | 'SHORT_DATE_TIME' | 'TINY_DATE_TIME' | 'FULL_DATE_TIME' | 'DATE' | 'SHORT_DATE' | 'TINY_DATE' | 'FULL_DATE';


export function D(str: any, type?: DateFormatType, utcadjust?: boolean): string { 
    let d = null;
    try {
        d = new Date(str);
        if (utcadjust) { d = new Date(d.getTime() - d.getTimezoneOffset() * 60000); }
        switch (type) {
            case undefined:
            case  'DATE_TIME':
                return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear() + ' ' + (((d.getHours() + 11) % 12) + 1) + ':' + digits(d.getMinutes(), 2) + ':' + digits(d.getSeconds(), 2) + (d.getHours() > 12 ? ' PM' : ' AM');
            case 'SHORT_DATE_TIME':
                return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear().toString().slice(-2) + ' ' + (((d.getHours() + 11) % 12) + 1) + ':' + digits(d.getMinutes(), 2) + (d.getHours() > 12 ? ' PM' : ' AM');
            case 'TINY_DATE_TIME':
                return (d.getMonth() + 1) + '/' + d.getDate() + ' ' + (((d.getHours() + 11) % 12) + 1) + ':' + digits(d.getMinutes(), 2) + (d.getHours() > 12 ? 'p' : 'a');
            case 'FULL_DATE_TIME':
                return digits(d.getMonth() + 1, 2) + '/' + digits(d.getDate(), 2) + '/' + d.getFullYear() + ' ' + digits(((d.getHours() + 11) % 12) + 1, 2) + ':' + digits(d.getMinutes(), 2) + (d.getHours() > 12 ? ' PM' : ' AM');
            case 'DATE':
                return d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear();
            case 'SHORT_DATE':
                return d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear().toString().slice(-2);    
            case 'TINY_DATE':
                return d.getMonth() + '/' + d.getDate();
            case 'FULL_DATE':
                return digits(d.getMonth() + 1, 2) + '/' + digits(d.getDate(), 2) + '/' + d.getFullYear();
            }
    } catch (e) { 
        return str;
    }
    
    return d ? new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).format(d).replaceAll(',', '') : String(d); 
}

function digits(n: number, digits: number) {
    return String(n).padStart(digits, '0');
}

export function setTranslations(trans: { [key: string]: any }) {
    Object.keys(trans).forEach((o) => { 
        if (typeof trans[o] === 'object') {
            if (trans[o]['default']) {
                trans[o][''] = trans[o]['default'];
                delete trans[o]['default'];
            }
        }
    });
    translations = trans;
}

export function T(str: string): string {
    const path = document.location.pathname;
    const trans = translations[str];
    if (trans) { 
        if (typeof trans === 'object') { 
            const keys = Object.keys(trans).sort().reverse();
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (path.startsWith(key)) {
                    return trans[key];
                }
            }
        }
    };
    return str;
}
