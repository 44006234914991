import { Box, Paper, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";

export default function BusinessCartDetailsProductFilters() {
    return (
        <Box maxWidth="md" sx={{ mx: 'auto' }}>
            <Paper className="content">
                <Typography variant="h6" gutterBottom>
                    {T("Product Filters")}
                </Typography>
                TODO
            </Paper>
        </Box>
    );
}
