import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { setTranslations } from './Util/Localize';
import ThemeProvider from './Components/ThemeProvider';
import Http404 from './Pages/http404';
import LayoutComponent from './Components/Layout/LayoutComponent';
import LegacyPage from './Components/Layout/LegacyPage';
import BusinessSettingsDetails from './Pages/Business/Settings/BusinessSettingsDetails';
import BusinessSettingsConfiguration from './Pages/Business/Settings/BusinessSettingsConfiguration';
import BusinessSettingsBranding from './Pages/Business/Settings/BusinessSettingsBranding';
import BusinessTeamMembers from './Pages/Business/Team/BusinessTeamMembers';
import BusinessCartProducts from './Pages/Business/Cart/BusinessCartProducts';
import BusinessCartPriceTiers from './Pages/Business/Cart/BusinessCartPriceTiers';
import BusinessCartUpsells from './Pages/Business/Cart/BusinessCartUpsells';
import BusinessCartPromoCodes from './Pages/Business/Cart/BusinessCartPromoCodes';
import BusinessCartWorkflowTasks from './Pages/Business/Cart/BusinessCartWorkflowTasks';
import BusinessCartDetailsQuestions from './Pages/Business/Cart/BusinessCartDetailsQuestions';
import BusinessCartDetailsProductFilters from './Pages/Business/Cart/BusinessCartDetailsProductFilters';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <Http404 />
    },
    {
      path: "/ux",
      element: <LayoutComponent />,
      children: [
        {
          path: "/ux",
          element: <Navigate to="/ux/Dashboard" />
        },
        {
          path: "/ux/Dashboard",
          element: <LegacyPage title="Dashboard" src="/dashboard/default.asp" />
        },
        {
          path: "/ux/Sites",
          element: <LegacyPage title="Sites" src="/sites/search.asp" />
        },
        {
          path: "/ux/Clients",
          element: <LegacyPage title="Clients" src="/clients/search.asp" />
        },
        {
          path: "/ux/Groups",
          element: <LegacyPage title="Groups" src="/groups/search.asp" />
        },
        {
          path: "/ux/Businesses",
          element: <LegacyPage title="Businesses" src="/brand/brands.asp" />
        },
        {
          path: "/ux/Prospects",
          element: <LegacyPage title="Prospects" src="/prospects/" />
        },
        {
          path: "/ux/Groups",
          element: <LegacyPage title="Groups" src="/groups/search.asp" />
        },
        {
          path: "/ux/Business",
          element: <Navigate to="/ux/Business/Summary" />
        },
        {
          path: "/ux/Business/Summary",
          element: <LegacyPage title="Business Summary" src="/brand/default.asp" />
        },
        {
          path: "/ux/Business/Settings/Details",
          element: <BusinessSettingsDetails />
        },
        {
          path: "/ux/Business/Settings/Branding",
          element: <BusinessSettingsBranding />
        },
        {
          path: "/ux/Business/Settings/Config",
          element: <BusinessSettingsConfiguration />
        },
        {
          path: "/ux/Business/Settings/EmailMessages",
          element: <LegacyPage title="Email Messages" src="/brand/Messages.asp" />
        },
        {
          path: "/ux/Business/Settings/Domains",
          element: <LegacyPage title="Domains" src="/brand/Domains.asp" />
        },
        {
          path: "/ux/Business/Settings/MobileApp",
          element: <LegacyPage title="Mobile App" src="/brand/MobileApp.asp" />
        },
        {
          path: "/ux/Business/Settings/AppIntegrations",
          element: <LegacyPage title="Permissions" src="/brand/apps.asp" />
        },
        {
          path: "/ux/Business/Settings/CustomDesigns",
          element: <LegacyPage title="Custom Designs" src="/brand/Designs.asp" />
        },
        {
          path: "/ux/Business/Settings/Pages",
          element: <LegacyPage title="Business Pages" src="/brand/Pages.asp" />
        },
        {
          path: "/ux/Business/Team/",
          element: <Navigate to="/ux/Business/Team/Members" />
        },
        {
          path: "/ux/Business/Team/Members",
          element: <BusinessTeamMembers />
        },
        {
          path: "/ux/Business/Team/PayTeam",
          element: <LegacyPage title="Pay Team" src="/brand/Payout.asp" />
        },
        {
          path: "/ux/Business/Team/Permissions",
          element: <LegacyPage title="Permissions" src="/brand/Permissions.asp" />
        },
        {
          path: "/ux/Business/Team/MarketAreas",
          element: <LegacyPage title="Market Areas" src="/brand/Markets.asp" />
        },
        {
          path: "/ux/Business/Team/SiteAttribution",
          element: <LegacyPage title="Site Attribution" src="/brand/Sponsors.asp" />
        },
        {
          path: "/ux/Business/BulkDelivery",
          element: <LegacyPage title="Bulk Delivery" src="/brand/BulkActivation.asp" />
        },
        {
          path: "/ux/Business/Reports",
          element: <LegacyPage title="Reports" src="/brand/Reports.asp" />
        },
        {
          path: "/ux/Business/PayTeam",
          element: <LegacyPage title="Pay Team" src="/brand/Payout.asp" />
        },
        {
          path: "/ux/Business/Cart",
          element: <Navigate to="/ux/Business/Cart/Products" />
        },
        {
          path: "/ux/Business/Cart/Products",
          element: <BusinessCartProducts />
        },
        {
          path: "/ux/Business/Cart/PriceTiers",
          element: <BusinessCartPriceTiers />
        },
        {
          path: "/ux/Business/Cart/Upsells",
          element: <BusinessCartUpsells />
        },
        {
          path: "/ux/Business/Cart/PromoCodes",
          element: <BusinessCartPromoCodes />
        },
        {
          path: "/ux/Business/Cart/WorkflowTasks",
          element: <BusinessCartWorkflowTasks />
        },
        {
          path: "/ux/Business/Cart/DetailQuestions",
          element: <BusinessCartDetailsQuestions />
        },
        {
          path: "/ux/Business/Cart/ProductFilters",
          element: <BusinessCartDetailsProductFilters />
        },
        {
          path: "/ux/Business/Cart/ServiceAreas",
          element: <LegacyPage title="Service Areas" src="/brand/ServiceAreas.asp" />
        },
        {
          path: "/ux/Business/Cart/ProductFees",
          element: <LegacyPage title="Product Fees" src="/brand/Fees.asp" />
        },
        {
          path: "/ux/Business/Cart/SalesTax",
          element: <LegacyPage title="Sales Tax" src="/brand/SalesTax.asp" />
        },
        {
          path: "/ux/Business/WebhooksAPIs",
          element: <LegacyPage title="Webhooks & APIs" src="/brand/api.asp" />
        },
        {
          path: "/ux/Business/ReferralCode",
          element: <LegacyPage title="Referral Code" src="/brand/referral.asp" />
        },
        {
          path: "/ux/Account",
          element: <Navigate to="/ux/Account/Basic" />
        },
        {
          path: "/ux/Account/Basic",
          element: <LegacyPage title="Account Basics" src="/account/default.asp" />
        },
        {
          path: "/ux/Account/Earnings",
          element: <LegacyPage title="Earnings" src="/account/earnings.asp" />
        },
        {
          path: "/ux/Account/ListingPages",
          element: <LegacyPage title="Listing Pages" src="/account/UserSites.asp" />
        },
        {
          path: "/ux/Account/Reports",
          element: <LegacyPage title="Account Reports" src="/account/Reports.asp" />
        },
        {
          path: "/ux/Account/PaymentTypes",
          element: <LegacyPage title="Payment Types" src="/account/PaymentTypes.asp" />
        },
        {
          path: "/ux/Account/Password",
          element: <LegacyPage title="Password" src="/account/UpdatePassword.asp" />
        },
        {
          path: "/ux/Account/AppIntegrations",
          element: <LegacyPage title="App Integrations" src="/account/apps.asp" />
        },
        {
          path: "/ux/Account/ServiceAreas",
          element: <LegacyPage title="Service Areas" src="/account/ServiceAreas.asp" />
        },
        {
          path: "/ux/Support",
          element: <LegacyPage title="Support" src="/support/default.asp" />
        },
        {
          path: "/ux/Admin",
          element: <LegacyPage title="Admin" src="/admin/default.asp" />
        }
      ]
    },
  ], {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true
    }
  })

  setTranslations({ 
    //"Business Name": "BO Name", 
    //"Public Email": { default: "Email", "/ux/Business/Details": "Business Email" } 
  });

  return (
    <ThemeProvider>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </ThemeProvider>
  );
}

export default App;
