import { useEffect, useState } from "react";
import { emptyFormData } from "../../Util/FormUtil";
import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";

export default function SelectInput(props: any) {

    const defaultValue = (props.defaultValue !== undefined) ? props.defaultValue : (props.data && props.data[props.name] !== undefined ? props.data[props.name] : "");
    const [value, setValue] = useState(defaultValue); 
    const [data, setData] = props.state ? props.state : [null, () => {}];
    const def: any = { displayEmpty: true, fullWidth: true, margin: "normal", size: "small", variant: "outlined" };
    const cancel = data && data.cancel;

    let merged: any = {...def, 
        ...props, 
        disabled: (data && data.saving ? true : props.disabled) || false,
        onChange: (e: any) => { 
            setValue(e.target.value);
            const mod = e.target.value !== defaultValue
            if (props.name) {
                setData((prev: any) => {
                    if (mod) { 
                        prev.data[props.name] = e.target.value;
                        prev.modified = true; 
                    }
                    else {
                        delete prev.data[props.name];
                        prev.modified = Object.keys(prev.data).length > 0;
                    }
                    return {...prev};
                });
            }
            if (props.onChange) {
                props.onChange(e, (mod) ? e.target.value : null); 
            }
        },
        className: (props.className || "") + ((data && data.data[props.name] !== undefined) ? " modified" : "").trim()
    };
    delete merged.state;
    delete merged.isLoading;
    delete merged.data;

    useEffect (() => {
        setValue(defaultValue);    
    }, [defaultValue]);

    useEffect(() => {
        if (cancel) { 
            setValue(defaultValue);
            setData(emptyFormData());
        }
    }, [cancel, defaultValue, setData]);

    return (props.isLoading || false) ? (
        <Box sx={{ pt: 2, pb: 1 }} >
            <Skeleton variant="rounded" height={"2.5rem"} />
        </Box>
    ) : (
        <FormControl fullWidth={merged.fullWidth} margin={merged.margin} size={merged.size} variant={merged.variant}
            className={(data && data.data[props.name] !== undefined) ? "modified" : ""}>
            <InputLabel shrink={value !== undefined}>{props.label}</InputLabel>
            <Select {...{...merged, margin: "none", value}}>
                {
                    props.options && (
                        Array.isArray(props.options) ?
                            props.options.map((option: any) => {
                                const opt = Array.isArray(option) ? option : [option, option];
                                return <MenuItem key={opt[0]} value={opt[0]}>{opt[1]}</MenuItem>;
                            })
                        : typeof props.options === "object" ?
                            Object.keys(props.options).map((key: any) => {
                                return <MenuItem key={key} value={key}>{props.options[key]}</MenuItem>;
                            }) 
                        : props.options
                    )
                }
            </Select>
        </FormControl>
    );
}
